@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./embla.css";
@import "mapbox-gl/dist/mapbox-gl.css";
@import "yet-another-react-lightbox/styles.css";

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply -translate-y-2 text-xsmall-regular;
  }

  input:focus ~ label {
    @apply left-0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    @apply border border-accent;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
  }
}

@layer components {
  .btn-instagram {
    background: radial-gradient(
        circle farthest-corner at 0% 150%,
        rgb(255, 225, 125) 0%,
        rgb(255, 205, 105) 12%,
        rgb(250, 145, 55) 25%,
        rgb(235, 65, 65) 41%,
        transparent 95%
      ),
      linear-gradient(-15deg, rgb(35, 75, 215) -10%, rgb(195, 60, 190) 65%);
    background-size: 120% 120%;
    animation: GradientBackground 5s ease-out infinite;
  }

  .content-container {
    @apply md:max-w-[1200px] w-full mx-auto;
  }

  .contrast-btn {
    @apply px-4 py-2 border border-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 ease-in;
  }

  .text-xsmall-regular {
    @apply text-[10px] leading-4 font-normal;
  }

  .text-small-regular {
    @apply text-xs leading-5 font-normal;
  }

  .text-small-semi {
    @apply text-xs leading-5 font-semibold;
  }

  .text-base-regular {
    @apply text-sm leading-6 font-normal;
  }

  .text-base-semi {
    @apply text-sm leading-6 font-semibold;
  }

  .text-large-regular {
    @apply text-base leading-6 font-normal;
  }

  .text-large-semi {
    @apply text-base leading-6 font-semibold;
  }

  .text-xl-regular {
    @apply text-2xl leading-[36px] font-normal;
  }

  .text-xl-semi {
    @apply text-2xl leading-[36px] font-semibold;
  }

  .text-2xl-regular {
    @apply text-[30px] leading-[48px] font-normal;
  }

  .text-2xl-semi {
    @apply text-[30px] leading-[48px] font-semibold;
  }

  .text-3xl-regular {
    @apply text-3xl lg:text-[36px] leading-[48px] font-normal mb-4 text-primary uppercase lg:mt-8;
  }

  .text-3xl-semi {
    @apply text-[36px] leading-[48px] font-semibold;
  }

  .text-5xl-regular {
    @apply text-4xl lg:text-[52px] lg:leading-[3.5rem] mb-4 text-primary uppercase lg:mt-8;
  }

  .text-hero-title {
    @apply text-4xl lg:text-6xl mb-4 text-white uppercase font-black;
  }

  .h-dynamic {
    height: 100vh;
  }

  .h-sidebar {
    @apply h-[calc(70vh-(90px))] lg:h-[calc(100vh-90px)];
  }

  .canvas-container {
    @apply h-[calc(30vh)] lg:h-[calc(100vh-(82px+88px))];
  }

  @supports (height: 100dvh) {
    .h-dynamic {
      height: 100dvh;
    }

    .h-sidebar {
      @apply h-[calc(70dvh-(130px))] lg:h-[calc(100dvh-90px)];
    }

    .canvas-container {
      @apply h-[calc(30dvh)] lg:h-[calc(100dvh-(82px+88px))];
    }
  }

  .h-safe-screen {
    /* equivalent to 100dvh in major browsers */
    height: calc(
      100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
    );
  }

  @supports (-webkit-touch-callout: none) {
    .h-safe-screen {
      /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
      height: -webkit-fill-available;
    }
  }

  .masonry {
    @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-4;
  }
  .masonry--container {
    @apply columns-1 space-y-4;
  }

  .masonry__item {
    @apply relative max-h-full max-w-full;
  }

  .masonry--small {
    @apply h-[300px];
  }
  .masonry--large {
    @apply h-[600px];
  }

  .masonry__img {
    @apply h-full max-h-full max-w-full object-cover;
  }

  .text-shadow {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
}

html {
  height: -webkit-fill-available;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading font-normal tracking-[0.20rem];
}

canvas {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  @apply bg-white font-body tracking-[0.05rem] leading-7;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.btn {
  @apply font-normal tracking-[0.25rem] uppercase;
}
.btn-primary {
  @apply text-white bg-opacity-95 border-0;
}
webkit-fill-height {
  height: -webkit-fill-available;
}

.sidebar {
  width: 100%;
  background: white;
  height: 35vh;
  z-index: 1;
  overflow: auto;
}

.root-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
  height: 100%;
  width: 100%;
}

.artemis {
  @apply font-artemis;
}
.basic {
  @apply font-basic;
}
.lovely {
  @apply font-lovely;
}

/* ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #eee;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #010;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #fff;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #000;
}
::-webkit-scrollbar-track:active {
  background: #000;
}
::-webkit-scrollbar-corner {
  background: transparent;
} */

.loader {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15vw;
  font-family: "Artemis";
}

.canvas-loader {
  min-width: 100%;
  min-height: 100%;
}

.hero-align {
  @apply lg:max-w-xl lg:justify-self-end lg:mr-36;
}

.box {
  @apply h-[700px] lg:h-[750px];
}

.box:after {
  @apply shadow-xl;
  content: " ";
  border-bottom: 700px solid oklch(var(--s));
  border-right: 100vw solid transparent;
  position: absolute;
}

@media screen(lg) {
  .box:after {
    border-bottom: 750px solid oklch(var(--s));
  }
}

.scroll-tooltip {
  /* Avoid layout interference */
  width: max-content;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.mouse {
  width: 30px;
  height: 40px;
  border: 3px solid #333;
  border-radius: 60px;
  position: relative;
}
.mouse::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 70%;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 70%;
  }
}

.ribbon {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
}
.ribbon {
  --f: 0.5em;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.8;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#5d4a1f 0 0) 51% / var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),
    100% 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    var(--f) calc(100% - var(--f)),
    0 100%,
    0 calc(100% - var(--f)),
    999px calc(100% - var(--f) - 999px),
    calc(100% - 999px) calc(100% - var(--f) - 999px)
  );
  transform: translate(calc((1 - cos(45deg)) * 100%), -100%) rotate(45deg);
  transform-origin: 0% 100%;
  background-color: black;
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%); */
}

.gold-bg {
  /* background:  linear-gradient(0deg, #7D3E00 -7.62%, #FFC170 14.51%, #FFEED8 32.4%, #FFC170 84.95%, #7D3E00 106.96%); */
  /* background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%); */
  /* background-image: repeating-linear-gradient(to right, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, #d3b15f 27%, #faf0a0 35%, #ffffc2 40%, #faf0a0 50%, #d3b15f 58%, #be8c3c 67%, #b17b32 77%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%); */
  background: linear-gradient(
    162.76deg,
    #f0f444 15.96%,
    #b0a225 54%,
    rgba(198, 244, 68, 0.77) 89%
  );
}
